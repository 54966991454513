import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import {
    Box,
    Button,
    TextField,
    Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import { getUserId } from '../../helpers/auth';
import { niceDateTime } from '../../helpers/converters';


interface Note {
    id: number;
    body?: string;
    updated_date?: string;
    updated_by_user?: {
        name: string;
    };
}

interface NoteFieldProps {
    initialNoteState: Note;
}

export const NoteField = ({ initialNoteState }: NoteFieldProps) => {
    const [isEditing, setIsEditing] = useState(false);
    const [noteText, setNoteText] = useState(initialNoteState.body || '');
    const [noteState, setNoteState] = useState(initialNoteState);

    const handleSave = async () => {
        try {
            setIsEditing(false);
            const updatedNote = await axios.patch(
                `/api/v1/uw/note/${noteState.id}`,
                {
                    body: noteText,
                    updated_by_user_id: Number(getUserId()),
                },
            );
            setNoteState(updatedNote.data);
        } catch (error) {
            console.error('Error saving note', error);
        }
    };

    const handleCancel = () => {
        setIsEditing(false);
        setNoteText(noteState.body || '');
    };

    if (isEditing) {
        return (
            <Box>
                <TextField
                    multiline
                    fullWidth
                    value={noteText}
                    onChange={(e) => setNoteText(e.target.value)}
                />
                <Box sx={{ mt: 1, display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={handleCancel}
                        startIcon={<CloseIcon />}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={handleSave}
                        startIcon={<SaveIcon />}
                    >
                        Save
                    </Button>
                </Box>
            </Box>
        );
    }

    return (
        <Box
            onClick={() => setIsEditing(true)}
            sx={{ cursor: 'pointer', '&:hover': { bgcolor: 'action.hover' } }}
        >
            <Typography>{noteState.body}</Typography>
            {noteState.updated_date && noteState.updated_by_user && (
                <Typography variant="caption" color="text.secondary">
                    Last updated by {noteState.updated_by_user.name} on{' '}
                    {niceDateTime(new Date(noteState.updated_date))}
                </Typography>
            )}
        </Box>
    );
}; 