import { DocumentApprovalStatus } from "@backend/constants";
import { UserGroups, RequiredFor, RequiredDocumentSubType, FileUploadSource } from "./constants";

export interface RequiredDocument {
    id: string;
    approval_date?: Date;
    approval_status: DocumentApprovalStatus;
    approved_by_user_username?: string;
    approved_by_user?: SsoUsers;
    can_upload?: keyof typeof UserGroups;
    can_view?: keyof typeof UserGroups;
    created_at: Date;
    created_by: string;
    created_by_user_id?: number;
    created_by_user?: SsoUsers;
    document_approval_source: DocumentApprovalSource;
    document_type_id: string;
    document_type: DocumentType;
    files: FileType[];
    is_deleted: boolean;
    is_gallery: boolean;
    is_required?: boolean;
    is_single_file: boolean;
    month?: number;
    owner_user_id?: number;
    owner?: SsoUsers;
    parent_id: string;
    required_for_step?: keyof typeof RequiredFor;
    requirement_description: string;
    requirement_name: string;
    requires_approval: boolean;
    sub_type?: keyof typeof RequiredDocumentSubType;
    sunstone_document_id: string;
    updated_at: Date;
    updated_by: string;
    year?: number;
}

interface UwManualOverrides {
    id: number;
    parent_id: string;
    parent_type: string;
    name: string;
    value?: number;
    bool_value?: boolean;
    user_id: number;
    user: SsoUsers;
    created_at: Date;
}

interface SsoUsers {
    id: number;
    login?: LoginPassword;
    username: string;
    name: string;
    group: string;
    active: boolean;
    required_documents: RequiredDocument[];
    required_documents_created: RequiredDocument[];
    required_documents_owner: RequiredDocument[];
    overrides: UwManualOverrides[];
}

export enum DocumentApprovalSource {
  DOCUSIGN = 'DOCUSIGN',
  UPLOAD = 'UPLOAD',
}

interface LoginPassword {
    id: number;
    userId: number;
    user: SsoUsers;
    password: string;
  allowed: boolean;
}
    
export interface Note {
  id: number;
  body?: string;
  updated_date?: string;
  updated_by_user?: {
      name: string;
  };
}

export interface Template {
  id: number;
  name: string;
  documentTypes: TemplateDocumentType[];
}

export interface TemplateDocumentType {
  id: number;
  template_id: number;
  template: Template;
  document_type_id: string;
  document_type: DocumentType;
  internal_notes: string;
  external_notes: string;
}

export interface DocumentType {
  id: string;
  name: string;
  crb_abbrev: string;
  system_name: string;
  required_documents: RequiredDocument[];
  is_required: boolean;
  is_active: boolean;
  templateDocumentTypes: TemplateDocumentType[];
}

export interface CustomDocumentType {
  name: DocumentType['name'] | string;
  isRequired: DocumentType['is_required'];
}

export interface OracleInsights {
  id: number;
  application_id: string;
  type: string;
  source: string;
  output: any;
  processed_date: Date;
  file_id?: string;
  file?: FileType;
}

export interface FileType {
  id: string;
  required_doc_id: string;
  required_document: RequiredDocument;
  s3_url: string;
  version_id: string;
  file_name: string;
  is_deleted: boolean;
  is_public: boolean;
  created_at: Date;
  created_by: string;
  upload_source: FileUploadSource;
  OracleInsights: OracleInsights[];
}
    
export enum TemplateNames {
  DOCUMENT_TYPES = 'Document Types',
}
