export const toCurrency = (value: number) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return formatter.format(value);
};

const extractDate = (dateStr: string): Date | undefined => {
  if (!dateStr) return undefined;
  let date: string = trimSlashes(dateStr);
  date = date.slice(date.indexOf('(') + 1, date.indexOf('-'));
  return new Date(Number(date));
};

// "1980-01-01T08:00:00.000Z" -> "Jan 1, 1980"
export const niceDate = (fullDate: string | Date): string => {
  if (!fullDate) return '';

  let date: Date;
  if (typeof fullDate === 'string') {
    date = new Date(fullDate);
  } else {
    date = fullDate;
  }

  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
};

// Format like "7/18/2023 10:40pm"
const dateFormatter = new Intl.DateTimeFormat('en-US', {
  day: 'numeric',
  month: 'numeric',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  hour12: true,
});

export const niceDateTime = (date: Date) => {
  if (typeof date === 'string') {
    date = new Date(date);
  }

  return dateFormatter.format(date).split(',').join('');
};

const trimSlashes = (str: string) => str?.replace(/\//g, '');
/**
 *
 * @param {string} dateStr The Date string in /Date(902329018-0000)/ format
 * @returns A long Date/Time formatted string
 */
export const toDate = (dateStr: string) => {
  const date = extractDate(dateStr);
  return new Intl.DateTimeFormat('en-US', {
    dateStyle: 'long',
    timeStyle: 'long',
  }).format(date);
};

const intlDateOptions: Intl.DateTimeFormatOptions = {
  year: '2-digit',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
};

export const toSimpleDate = (dateStr: string, withSeconds: boolean = false) => {
  const date = extractDate(dateStr);
  const options = { ...intlDateOptions };
  if (withSeconds) options.second = '2-digit';
  return Intl.DateTimeFormat('en-US', options).format(date);
};

export const standardToShortDate = (
  dateStr: string,
  withSeconds: boolean = false,
) => {
  const date = new Date(dateStr);
  const options = { ...intlDateOptions };
  if (withSeconds) options.second = '2-digit';
  return new Intl.DateTimeFormat('en-US', options).format(date);
};

export const toAgeInDays = (dateStr: string): number => {
  const date = extractDate(dateStr);
  if (!date) return 0;
  const dateUnix = date.getTime() ?? 0;
  return Math.ceil((Date.now() - dateUnix) / (24 * 3600 * 1000));
};

/**
 * Converts a timestamp to a short Date/Time formatted string in the en-US locale.
 *
 * The date is converted to the local timezone.
 * @param {number|string} timestamp in milliseconds
 */
export const tsToDate = (timestamp: number | string) => {
  if (!timestamp) return '';
  const date = new Date(timestamp);
  return new Intl.DateTimeFormat('en-US', {
    dateStyle: 'short',
    timeStyle: 'short',
  }).format(date);
};

export const extractBusinessName = (originalLoanInfo: any) => {
  if (originalLoanInfo?.Field6) {
    const field6 = JSON.parse(originalLoanInfo?.Field6);
    return field6.Name;
  }
  return `${originalLoanInfo?.BorrowerFirstName} ${originalLoanInfo?.BorrowerLastName}`;
};

export const normalizeSfKey = (sfKey: string) =>
  sfKey.split('__')[0].replace(/_/g, ' ');

export const fccrToLabel = (fccr: number) => {
  if (fccr >= 1.1) return 'good';
  if (fccr >= 1.0 && fccr < 1.1) return 'okay';
  if (fccr > Number.NEGATIVE_INFINITY && fccr < 1) return 'bad';
  else return 'not-found';
};

export const minDscrToLabel = (minDscr: number) => {
  if (minDscr >= 0.6) return 'good';
  if (minDscr > Number.NEGATIVE_INFINITY && minDscr < 0.6) return 'bad';
  else return 'not-found';
};

export const avgDscrToLabel = (avgDscr: number) => {
  if (avgDscr >= 0.8) return 'good';
  if (avgDscr > Number.NEGATIVE_INFINITY && avgDscr < 0.8) return 'bad';
  else return 'not-found';
};

