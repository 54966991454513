import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';

export const StyledTab = styled(Tab)(({ theme }) => ({
  fontSize: '11px',
  paddingLeft: '5px',
  paddingRight: 0,
  paddingY: '5px',
  borderLeft: '1px solid #DDD',
  borderRight: '1px solid #DDD',
  borderBottom: '2px solid #DDD',
  '& .MuiTabScrollButton-root': {
    width: '20px',
  },
  '&.MuiTab-root': {
    minHeight: '20px',
    height: '20px',
  },
}));
