import { Box } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from '../components/Header';
import { ToolsSideNav } from '../components/navigation/ToolsSideNav';

export const ToolsLayout = () => {
  return (
    <Box>
      <Header />
      <Box sx={{ display: 'flex', minHeight: '88.5vh', marginTop: '73px' }}>
        <ToolsSideNav />
        <Box sx={{ bgcolor: '#ECECEC', width: '100%' }}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};
