import { stateCodeToState } from "@backend/constants";
import { InsertDriveFile } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
  Typography
} from "@mui/material";
import axios from "axios";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  Title,
  Tooltip
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { MONTHS } from "../../../helpers/constants";
import { downloadCSV, escapeCommas } from "../../../helpers/files";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    ChartDataLabels
  )

const buildingTypes: { [key: string]: string } = {
  "fullservicerestaurant": "Full Service Restaurant",
  "hospital": "Hospital",
  "largehotel": "Large Hotel",
  "largeoffice": "Large Office",
  "mediumoffice": "Medium Office",
  "outpatient": "Outpatient",
  "primaryschool": "Primary School",
  "quickservicerestaurant": "Quick Service Restaurant",
  "retailstandalone": "Retail Standalone",
  "retailstripmall": "Retail Strip Mall",
  "secondaryschool": "Secondary School",
  "smallhotel": "Small Hotel",
  "smalloffice": "Small Office",
  "warehouse": "Warehouse"
}

interface EstimateResponse {
  total_yearly_consumption_kwh: number;
  month_consumption_list: {
    [key: string]: number;
  };
}

export const ConsumptionEstimatorCalc = () => {
  const [consumption, setConsumption] = useState("")
  const [month, setMonth] = useState("") 
  const [buildingType, setBuildingType] = useState("")
  const [state, setState] = useState("")
  const [estimate, setEstimate] = useState<EstimateResponse | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const handleEstimate = async () => {
    if (!consumption || !month || !buildingType || !state) return;
    
    setError(null);
    setIsLoading(true);
    
    try {
        const result = await axios.get(
            `/api/v1/uw/consumption-estimator`, {
                params: {
                    utility_bill_consumption: consumption,
                    utility_bill_month: month,
                    building_type: buildingType,
                    state_code: state
                }
            }
        );
        setEstimate(result.data);
    } catch (err: any) {
        console.error(err);
        setError(err.message || "Failed to fetch estimate. Please try again.");
    } finally {
        setIsLoading(false);
    }
  };

  const handleDownloadCSV = () => {
    if (!estimate) return;

    // Create CSV content
    const inputs = 'Inputs\n' +
      'One Month Consumption (kWh),' + escapeCommas(consumption) + '\n' +
      'Month of Consumption,' + escapeCommas(month) + '\n' +
      'Building Type,' + escapeCommas(buildingTypes[buildingType]) + '\n' +
      'State,' + escapeCommas(stateCodeToState[state]) + '\n\n';

    const output = 'Output\n' +
      'Estimated Yearly Consumption (kWh),' + escapeCommas(estimate.total_yearly_consumption_kwh.toLocaleString()) + '\n\n';

    const headers = 'Month,Consumption (kWh)\n';
    const monthlyConsumption = Object.entries(estimate.month_consumption_list)
      .map(([month, value]) => `${month},${escapeCommas(value.toLocaleString())}`)
      .join('\n');
    
      // Create and trigger download
    downloadCSV("", inputs + output + headers + monthlyConsumption, 'consumption_estimate_export');
  };

  const chartData = estimate
    ? {
        labels: Object.keys(estimate.month_consumption_list).map(month => month.charAt(0).toUpperCase() + month.slice(1)),
        datasets: [
          {
            label: 'Monthly Consumption (kWh)',
            data: Object.values(estimate.month_consumption_list),
            backgroundColor: 'rgba(25, 118, 210, 0.6)',
            borderColor: 'rgba(25, 118, 210, 1)',
            borderWidth: 1,
          },
        ],
      }
    : null


  const chartOptions = {
    responsive: true,
    plugins: {
        title: {
          display: true,
          text: 'Estimated Monthly Energy Consumption (kWh)',
        },
        datalabels: {
          anchor: 'end',
          align: 'bottom',
          formatter: (value) => value.toLocaleString(),
          font: {
            weight: 'bold'
          }
        }
      },
      scales: {
        x: {
          type: 'category',
          title: {
            display: true,
            text: 'Month'
          }
        },
        y: {
          type: 'linear',
          title: {
            display: true,
            text: 'Consumption (kWh)'
          }
        }
      }
  }

  return (
    <Card sx={{ maxWidth: 1000, margin: 'auto', mt: 4 }}>
      <CardHeader
        title="Energy Consumption Estimator"
        subheader="Estimate yearly and monthly energy consumption based on one month's data"
      />
      <CardContent>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="One Month Consumption (kWh)"
              value={consumption}
              onChange={(e) => setConsumption(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              select
              label="Month of Consumption"
              value={month}
              onChange={(e) => setMonth(e.target.value)}
              fullWidth
            >
              {MONTHS.map((m) => (
                <MenuItem key={m} value={m}>{m}</MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              select
              label="Building Type"
              value={buildingType}
              onChange={(e) => setBuildingType(e.target.value)}
              fullWidth
            >
              {Object.entries(buildingTypes).map(([value, label]) => (
                <MenuItem key={value} value={value}>{label}</MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              select
              label="State"
              value={state}
              onChange={(e) => setState(e.target.value)}
              fullWidth
            >
              {Object.entries(stateCodeToState).map(([code, stateName]) => (
                <MenuItem key={code} value={code}>
                  {stateName} ({code})
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <Button 
            variant="contained" 
            onClick={handleEstimate} 
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : "Estimate Consumption"}
          </Button>
        </Box>
        {error && (
          <Typography color="error" sx={{ mt: 2 }}>
            {error}
          </Typography>
        )}
        {estimate && (
          <Box sx={{ 
            mt: 4, 
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <Typography variant="h6" gutterBottom>
              Estimated Yearly Consumption: {estimate.total_yearly_consumption_kwh.toLocaleString()} kWh
            </Typography>
            <Button onClick={handleDownloadCSV}>
                <InsertDriveFile sx={{ fontSize: '17px' }} />
                <Typography variant="caption" ml={1} color={'text.primary'}>Download CSV</Typography>
              </Button>
            <Box sx={{ 
              height: 400, 
              mt: 2, 
              width: '100%', 
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              {chartData && <Bar options={chartOptions as any} data={chartData} />}
            </Box>
          </Box>
        )}
      </CardContent>
    </Card>
  )
};