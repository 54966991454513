import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { MainLayout } from './layouts/MainLayout';
import { LoginPage } from './pages/LoginPage';
import { fetchRailsLoader, Rails } from './pages/Rails';
import { SSOAuth } from './pages/SSOAuth';

import { ThemeProvider } from '@mui/material';
import { defaultTheme } from './theme';

import './assets/style.css';
import { AuthGuard } from './components/Auth/AuthGuard';
import { AdminPage } from './pages/AdminPage';
import { ErrorPage } from './pages/ErrorPage';
import { FileBrowser } from './pages/FileBrowser';
import { fetchAllProjects, FinOpsDashboard } from './pages/FinOpsDashboard';
import { Home } from './pages/Home';
import {
  fetchProjectChecklistData,
  ProjectChecklist,
} from './pages/ProjectChecklist';
import { ApplicationList } from './pages/underwriting/ApplicationList';
import { UwDataTools } from './pages/underwriting/UwDataTools';

import React from 'react';
import { FileDisplay } from './components/FileBrowser/FileDisplay';
import { UserGroup } from './helpers/auth';
import {
  fetchLoanApplication,
  LoanApplicationLayout,
} from './layouts/LoanApplicationLayout';
import { FileManagement } from './pages/loanOps/FileManagement';
import { LoanApplication } from './pages/loanOps/LoanApplication';
import {
  fetchApplicationReports,
  UwReport,
} from './pages/underwriting/UwReport';

import { AdminGuard } from './components/Auth/AdminGuard';
import { GroupGuard } from './components/Auth/GroupGuard';
import { ToolsLayout } from './layouts/ToolsLayout';
import { AppDetails } from './pages/loanOps/application-details/AppDetails';
import { ChildLoan, fetchChildLoanData } from './pages/loanOps/ChildLoan';
import { PreScreen } from './pages/loanOps/PreScreen';
import { ACRCalculator } from './pages/underwriting/tools/ACRCalculator';
import { Announcements } from './pages/underwriting/tools/Announcements';
import { ConsumptionEstimatorCalc } from './pages/underwriting/tools/ConsumptionEstimatorCalc';
import { Powerlytics } from './pages/underwriting/tools/Powerlytics';
import { SunstoneAPITester } from './pages/underwriting/tools/SunstoneAPITester';
import { UWTool } from './pages/underwriting/UWTool';
import { RequiredDocumentsProvider } from './contexts/RequiredDocumentsContext';

const routes = createBrowserRouter([
  {
    path: '/',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        path: '/',
        element: <Home />,
      },
      {
        path: '/rails/:fundingInfoId/:appId',
        element: <Rails />,
        loader: fetchRailsLoader,
      },
      {
        path: '/child-loan/:appId/:fundingInfoId',
        element: <ChildLoan />,
        loader: fetchChildLoanData,
      },
      {
        path: '/loans',
        element: <ApplicationList />,
      },
      {
        path: '/files',
        element: <FileBrowser />,
      },
      {
        path: '/funded-projects',
        element: (
          <GroupGuard groups={[UserGroup.FIN_OPS, UserGroup.UW]}>
            <FinOpsDashboard />
          </GroupGuard>
        ),
        loader: fetchAllProjects,
        errorElement: <ErrorPage />,
      },
      {
        path: '/project/:projectId/checklist',
        element: (
          <GroupGuard groups={[UserGroup.FIN_OPS, UserGroup.UW]}>
            <ProjectChecklist />
          </GroupGuard>
        ),
        loader: fetchProjectChecklistData,
      },
      {
        path: '/admin',
        element: (
          <AdminGuard>
            <AdminPage />
          </AdminGuard>
        ),
      },
      {
        path: '/underwriting/data-tools',
        element: (
          <GroupGuard groups={[UserGroup.UW, UserGroup.LOAN_OPS_UW]}>
            <UwDataTools />
          </GroupGuard>
        ),
      },
      {
        path: '/underwriting/app-list',
        element: <ApplicationList />,
      },
      {
        path: '/underwriting/company/reports/:appId',
        element: <UwReport />,
        loader: fetchApplicationReports,
      },
    ],
  },
  {
    path: '/files/preview/:fileId',
    element: (
      <GroupGuard groups={[UserGroup.FIN_OPS]}>
        <FileDisplay />
      </GroupGuard>
    ),
  },
  {
    path: '/loans/application/:appId',
    element: (
      <AuthGuard>
        <LoanApplicationLayout />
      </AuthGuard>
    ),
    loader: fetchLoanApplication,
    children: [
      {
        path: '/loans/application/:appId',
        element: (
          <GroupGuard groups={[UserGroup.LOAN_OPS, UserGroup.LOAN_OPS_UW]}>
            <LoanApplication />
          </GroupGuard>
        ),
      },
      {
        path: 'documents',
        element: (
            <FileManagement />
        ),
      },
      {
        path: 'score-card',
        element: <PreScreen />,
      },
      {
        path: 'app-details',
        element: <AppDetails />,
      },
      {
        path: 'uw-tool',
        element: <UWTool />,
      },
    ],
  },
  {
    path: '/underwriting/tools',
    element: (
      <AuthGuard>
        <ToolsLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'acr-calculator',
        element: <ACRCalculator />,
      },
      {
        path: 'consumption-estimator',
        element: <ConsumptionEstimatorCalc />,
      },
      {
        path: 'powerlytics',
        element: <Powerlytics />,
      },
      {
        path: 'announcements',
        element: <Announcements />,
      },
      {
        path: 'sunstone-api-tester',
        element: <SunstoneAPITester />,
      },
    ],
  },
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/sso-auth',
    element: <SSOAuth />,
  },
]);

export const App = () => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <RequiredDocumentsProvider>
        <RouterProvider router={routes} />
      </RequiredDocumentsProvider>
    </ThemeProvider>
  );
};
