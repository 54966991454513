import { ReactElement } from 'react';

type ShowIfProps = {
  every?: boolean[];
  some?: boolean[];
  children: ReactElement;
};
export const ShowIf: React.FC<ShowIfProps> = ({ children, every, some }) => {
  let shouldRender = true;
  if (every) shouldRender &&= every.every((condition) => condition);
  if (some) shouldRender &&= some.some((condition) => condition);
  if (shouldRender) return children;
  return null;
};

