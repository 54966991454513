// The main layout is protected by default

import { Outlet } from 'react-router-dom';
import { Box, Container } from '@mui/material';
import { Header } from '../components/Header';
import React from 'react';

const layoutStyles = {
  contentRoot: {
    paddingTop: '20px',
    minHeight: `calc(100% - 10px)`,
  },
};

// Every child element of the MainLayout is protected
export function MainLayout() {
  return (
    <Box
      sx={{
        backgroundColor: '#efefef',
        width: '100%',
        minHeight: '95vh',
        pb: '5vh',
      }}
    >
      <Header />
      <Box className={'contentRoot content'} style={layoutStyles.contentRoot} sx={{ marginTop: '73px' }}>
        <Outlet />
      </Box>
    </Box>
  );
}
