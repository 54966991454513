import AddIcon from '@mui/icons-material/Add';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Button, CircularProgress, Grid, IconButton, TextField, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { getUserId } from '../../helpers/auth';
import { NoteType } from '../../helpers/constants';
import { NoteField } from './NoteField';

export const NotesSection = ({
    requiredDocumentId
}) => {
    const [externalNoteState, setExternalNoteState] = useState<any>(null);
    const [internalNoteState, setInternalNoteState] = useState<any>(null);
    const [showExternalNoteField, setShowExternalNoteField] = useState(false);
    const [showInternalNoteField, setShowInternalNoteField] = useState(false);
    const [newExternalNote, setNewExternalNote] = useState('');
    const [newInternalNote, setNewInternalNote] = useState('');
    const [noteDeleteConfirmation, setNoteDeleteConfirmation] = useState<NoteType | null>(null);
    const [isLoading, setIsLoading] = useState({
        noteCreate: false,
        noteDelete: false,
    });

    useEffect(() => {
        axios.get(`/api/v1/uw/required-document/${requiredDocumentId}?include_notes=true`)
            .then(response => {
                setExternalNoteState(response.data.external_note);
                setInternalNoteState(response.data.internal_note);
            })
            .catch(error => {
                console.error('Error fetching notes:', error);
            });
    }, [requiredDocumentId]);

    const handleNoteCreate = async (noteType: NoteType, noteContent: string) => {
        setIsLoading(prev => ({ ...prev, noteCreate: true }));
        try {
            const noteResponse = await axios.post('/api/v1/uw/note', {
                required_document_id: requiredDocumentId,
                note_type: noteType,
                body: noteContent,
                created_by_user_id: Number(getUserId()),
                updated_by_user_id: Number(getUserId()),
            });

            if (noteType === NoteType.External) {
                setExternalNoteState(noteResponse.data);
                setShowExternalNoteField(false);
                setNewExternalNote('');
            } else {
                setInternalNoteState(noteResponse.data);
                setShowInternalNoteField(false);
                setNewInternalNote('');
            }
        } catch (error) {
            console.error('Error saving note:', error);
        } finally {
            setIsLoading(prev => ({ ...prev, noteCreate: false }));
        }
    };

    const handleNoteDelete = async (noteType: NoteType) => {
        setIsLoading(prev => ({ ...prev, noteDelete: true }));
        try {
            const isExternalNote = noteType === NoteType.External;
            const noteUpdates = { is_deleted: true };
            const noteId = isExternalNote ? externalNoteState.id : internalNoteState.id;

            await axios.patch(`/api/v1/uw/note/${noteId}`, noteUpdates);

            if (isExternalNote) {
                setExternalNoteState(null);
            } else {
                setInternalNoteState(null);
            }
        } catch (error) {
            console.error('Error deleting note:', error);
        } finally {
            setIsLoading(prev => ({ ...prev, noteDelete: false }));
            setNoteDeleteConfirmation(null);
        }
    };

    return (
        <Box sx={{ pl: '5rem', pr: '1rem', py: 1 }}>
            <Grid container sx={{ backgroundColor: '#FFFFFF', border: '1px solid #ECECEC', borderRadius: '4px' }}>
                {/* External Note */}
                <Grid item xs={6}>
                    <Box sx={{ padding: '1rem' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                                <ChatBubbleOutlineIcon sx={{ fontSize: 16, color: '#4dadf9', fontWeight: 'bold' }} />
                                <Typography variant="body2" fontWeight={700}>External Note</Typography>
                            </Box>
                            {externalNoteState ? (
                                noteDeleteConfirmation === NoteType.External ? (
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        {isLoading.noteDelete ? (
                                            <CircularProgress size={16} />
                                        ) : (
                                            <>
                                                <Typography variant="body2" color="error" sx={{ fontSize: '12px' }}>
                                                    Delete note?
                                                </Typography>
                                                <IconButton
                                                    size="small"
                                                    onClick={() => handleNoteDelete(NoteType.External)}
                                                    sx={{ padding: 0, color: 'success.main' }}
                                                >
                                                    <CheckIcon fontSize="small" />
                                                </IconButton>
                                                <IconButton
                                                    size="small"
                                                    onClick={() => setNoteDeleteConfirmation(null)}
                                                    sx={{ padding: 0, color: 'error.main' }}
                                                >
                                                    <CloseIcon fontSize="small" />
                                                </IconButton>
                                            </>
                                        )}
                                    </Box>
                                ) : (
                                    <IconButton
                                        size="small"
                                        onClick={() => setNoteDeleteConfirmation(NoteType.External)}
                                        sx={{ padding: 0 }}
                                    >
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                )
                            ) : (
                                <IconButton
                                    size="small"
                                    onClick={() => setShowExternalNoteField(true)}
                                    sx={{ padding: 0 }}
                                >
                                    <AddIcon fontSize="small" />
                                </IconButton>
                            )}
                        </Box>
                        {externalNoteState ? (
                            <NoteField initialNoteState={externalNoteState} />
                        ) : showExternalNoteField && (
                            <Box sx={{ mt: 1 }}>
                                <TextField
                                    fullWidth
                                    multiline
                                    value={newExternalNote}
                                    onChange={(e) => setNewExternalNote(e.target.value)}
                                    variant="outlined"
                                    size="small"
                                />
                                <Box sx={{ mt: 1, display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
                                    <Button
                                        size="small"
                                        startIcon={<CloseIcon />}
                                        onClick={() => {
                                            setShowExternalNoteField(false);
                                            setNewExternalNote('');
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        size="small"
                                        variant="contained"
                                        startIcon={isLoading.noteCreate ? <CircularProgress size={16} /> : <SaveIcon />}
                                        onClick={() => handleNoteCreate(NoteType.External, newExternalNote)}
                                        disabled={isLoading.noteCreate}
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Grid>

                {/* Internal Note */}
                <Grid item xs={6}>
                    <Box sx={{ padding: '1rem', display: 'flex', flexDirection: 'column', gap: '1px' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                                <ChatBubbleOutlineIcon sx={{ fontSize: 16, color: '#4dadf9', fontWeight: 'bold' }} />
                                <Typography variant="body2" fontWeight={700}>Internal Note</Typography>
                            </Box>
                            {internalNoteState ? (
                                noteDeleteConfirmation === NoteType.Internal ? (
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        {isLoading.noteDelete ? (
                                            <CircularProgress size={16} />
                                        ) : (
                                            <>
                                                <Typography variant="body2" color="error" sx={{ fontSize: '12px' }}>
                                                    Delete note?
                                                </Typography>
                                                <IconButton
                                                    size="small"
                                                    onClick={() => handleNoteDelete(NoteType.Internal)}
                                                    sx={{ padding: 0, color: 'success.main' }}
                                                >
                                                    <CheckIcon fontSize="small" />
                                                </IconButton>
                                                <IconButton
                                                    size="small"
                                                    onClick={() => setNoteDeleteConfirmation(null)}
                                                    sx={{ padding: 0, color: 'error.main' }}
                                                >
                                                    <CloseIcon fontSize="small" />
                                                </IconButton>
                                            </>
                                        )}
                                    </Box>
                                ) : (
                                    <IconButton
                                        size="small"
                                        onClick={() => setNoteDeleteConfirmation(NoteType.Internal)}
                                        sx={{ padding: 0 }}
                                    >
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                )
                            ) : (
                                <IconButton
                                    size="small"
                                    onClick={() => setShowInternalNoteField(true)}
                                    sx={{ padding: 0 }}
                                >
                                    <AddIcon fontSize="small" />
                                </IconButton>
                            )}
                        </Box>
                        {internalNoteState ? (
                            <NoteField initialNoteState={internalNoteState} />
                        ) : showInternalNoteField && (
                            <Box sx={{ mt: 1 }}>
                                <TextField
                                    fullWidth
                                    multiline
                                    value={newInternalNote}
                                    onChange={(e) => setNewInternalNote(e.target.value)}
                                    variant="outlined"
                                    size="small"
                                />
                                <Box sx={{ mt: 1, display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
                                    <Button
                                        size="small"
                                        startIcon={<CloseIcon />}
                                        onClick={() => {
                                            setShowInternalNoteField(false);
                                            setNewInternalNote('');
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        size="small"
                                        variant="contained"
                                        startIcon={isLoading.noteCreate ? <CircularProgress size={16} /> : <SaveIcon />}
                                        onClick={() => handleNoteCreate(NoteType.Internal, newInternalNote)}
                                        disabled={isLoading.noteCreate}
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default NotesSection; 