import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import { applicationWorkflowSteps, WorkflowStep } from '../helpers/documentTypeMappings';
import { RequiredDocument as RequiredDocumentType } from '../helpers/types';
import { RequiredDocument } from './Underwriting/RequiredDocument';
import { BetterAccordion } from './BetterAccordion';


const DocumentsByWorkflowStepList = ({ docs, users, expandAllClicked, collapseAllClicked, isFiltering, }) => {

  const documentGroups = [
    ...new Set(Object.values(applicationWorkflowSteps)),
    'Other'
  ];
  // This is to determine which accordions (steps) are expanded (WorkflowStep[]). We are starting with all of them expanded
  const [expandedSteps, setExpandedSteps] = useState<WorkflowStep[]>(documentGroups);

  // This is needed to prevent the 'Collapse All' button from collapsing the accordions when the component is first mounted
  const hasMounted = useRef(false);

  // This is used to indicate that the 'Expand All' or 'Collapse All' for the accordions (steps) inside of this object was clicked (WorkflowStep: 1)
  // We'll increase its number by 1 each time it's clicked so it's different from the previous one
  // e.g.: { "Borrower App Submission": 1 }
  const [stepClickedExpandAll, setStepClickedExpandAll] = useState<Record<WorkflowStep, number>>({});
  const [stepClickedCollapseAll, setStepClickedCollapseAll] = useState<Record<WorkflowStep, number>>({});

  const groupedDocs: Record<WorkflowStep, RequiredDocumentType[]> = {};

  const handleAccordionChange = (panel: WorkflowStep) => (
    event: React.SyntheticEvent,
    isExpanded: boolean
  ) => {
    setExpandedSteps((prevExpanded) =>
      isExpanded
        ? [...prevExpanded, panel]
        : prevExpanded.filter((key) => key !== panel)
    );
  };

  useEffect(() => {
    if (hasMounted.current) {
      // Set the expanded steps to none when the parent's 'Collapse All' button is clicked
      setExpandedSteps([]);
    } else {
      // This is to prevent the 'Collapse All' button from collapsing the accordions when the component is first mounted
      hasMounted.current = true;
    }
  }, [collapseAllClicked]);

  useEffect(() => {
    // Set the expanded steps to all of them when the parent's 'Expand All' button is clicked
    setExpandedSteps(documentGroups);
  }, [expandAllClicked]);

  // Group documents based on the mapping
  docs.forEach((doc) => {
    const requiredForStep = doc.required_for_step;
    // TODO: Remove the reference to Other when we remove the 'Other' category
    const mappingGroup = requiredForStep ? applicationWorkflowSteps[requiredForStep] : 'Other'; 
    if (!groupedDocs[mappingGroup]) {
      groupedDocs[mappingGroup] = [];
    }
    groupedDocs[mappingGroup].push(doc);
  });

  return (
    <>
      {documentGroups.map((docGroup) => {
        let groupDocs = groupedDocs[docGroup] || [];
        // Sort documents alphabetically by a specific property, e.g., 'name'
        groupDocs = groupDocs.sort((a, b) => a.requirement_name.localeCompare(b.requirement_name));

        const accordionLabel = `${docGroup} (${groupDocs.length})`;

        return (
          // Show if not filtering or if filtering and there are documents in the group
          (!isFiltering || (isFiltering && groupDocs.length > 0)) && (
            <BetterAccordion
              key={docGroup}
            expanded={expandedSteps.includes(docGroup)}
            defaultExpanded={true}
            onChange={handleAccordionChange(docGroup)}
            sx={{
              boxShadow: 'none',
              '&.Mui-expanded': {
                margin: 0,
              },
              '&::before': {
                backgroundColor: 'transparent',
              },
            }}
          >
            <AccordionSummary
              aria-controls={`${docGroup}-content`}
              id={`${docGroup}-header`}
              className="flex-gap-0_5 flex-row-start-reverse"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderBottom: !expandedSteps.includes(docGroup) ? '1px solid #E0E0E0' : 'none',
                margin: 0,
                zIndex: 2,
                '& .MuiAccordionSummary-expandIconWrapper': {
                  transform: 'none'
                },
                '&.Mui-expanded': {
                  margin: 0,
                },
                '& .MuiAccordionSummary-content': {
                  margin: 0,
                },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: 1,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '.5rem',
                    pl: '.3rem',
                  '& .icon': {
                        transition: 'transform 0.3s ease', 
                        transform: expandedSteps.includes(docGroup) ? 'rotate(0deg)' : 'rotate(-90deg)',
                      }
                    }}
                  >
                    <ExpandMoreIcon className="icon" />
                  <Typography variant="h6"
                    sx={{ fontFamily: 'Lato, sans-serif', fontWeight: 700 }}
                  >
                    {accordionLabel}
                  </Typography>
                </Box>
                {groupDocs.length > 0 && (
                  <Box
                    sx={{ display: expandedSteps.includes(docGroup) ? 'flex' : 'none', alignItems: 'center', gap: '.5rem', justifyContent: 'space-between' }}
                >
                  <Typography
                    color={ "text.primary"}
                    onClick={(event) => {
                      event.stopPropagation()
                      setStepClickedExpandAll((prevExpanded) => ({
                        ...prevExpanded,
                        [docGroup]: (prevExpanded[docGroup] || 0) + 1
                      }));
                    }}
                    sx={{
                      textTransform: 'none',
                      fontFamily: 'Lato, sans-serif',
                      cursor: 'pointer',
                      textDecoration: 'underline',
                      fontWeight: 400
                    }}
                  >
                    Expand All
                  </Typography>
                  <Typography
                    color={"text.primary"}
                    onClick={(event) => {
                      event.stopPropagation()
                      setStepClickedCollapseAll((prevCollapsed) => ({
                        ...prevCollapsed,
                        [docGroup]: (prevCollapsed[docGroup] || 0) + 1
                      }));
                    }}
                    sx={{
                      textTransform: 'none',
                      fontFamily: 'Lato, sans-serif',
                      cursor: 'pointer',
                      textDecoration: 'underline',
                      fontWeight: 400
                    }}
                  >
                    Collapse All
                    </Typography>
                  </Box>
                )}
              </Box>
            </AccordionSummary>
            {groupDocs.length > 0 ? (
              <AccordionDetails
                sx={{
                  backgroundColor: '#F5F5F5',
                  p: 0,
                  zIndex:1,
                  borderBottom: '1px solid #E0E0E0',
                }}
              >
                <Grid container >
                  {groupDocs.map((doc) => (
                    <Grid item xs={12} key={doc.id}>
                      <RequiredDocument
                        key={doc.approval_status}
                        document={doc}
                        users={users}
                        stepClickedExpandAll={stepClickedExpandAll[docGroup] ?? 0}
                        stepClickedCollapseAll={stepClickedCollapseAll[docGroup] ?? 0}
                      />
                    </Grid>
                  ))}
                </Grid>
              </AccordionDetails>
            ) : (
              <AccordionDetails
                sx={{
                  borderBottom: '1px solid #E0E0E0',
                  borderTop: '1px solid #E0E0E0',
                  backgroundColor: '#F9FBFD',
                  p: '16px'
                }}
              >
                <Typography variant="body2" sx={{ fontFamily: 'Lato, sans-serif', pl: '2.5rem', backgroundColor: '#F9FBFD', color: '#868795' }}>
                  No documents in this step.
                </Typography>
              </AccordionDetails>
            )}
          </BetterAccordion>
          )
        );
      })}
    </>
  );
};

export default DocumentsByWorkflowStepList;
