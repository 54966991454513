import React, { forwardRef } from 'react';
import { SvgIconProps, SvgIcon } from '@mui/material';


export const UploadIcon = forwardRef<SVGSVGElement, SvgIconProps>(
    function UploadIcon(props, ref) {
      return (
        <SvgIcon ref={ref} {...props}>
          <svg width="15" height="15" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.5 5.61399L6.46937 6.56635L8.25 4.82372L8.25 11.6929L9.625 11.6929L9.625 4.82372L11.3987 6.56635L12.375 5.61399L8.9375 2.23679L5.5 5.61399Z" fill="#2196F3"/>
          <rect x="3.25" y="13.2896" width="11.25" height="1.47368" fill="#2196F3"/>
          </svg>
        </SvgIcon>
      );
    }
  );