import { styled } from '@mui/material/styles';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

export const StyledSwipeableDrawer = styled(SwipeableDrawer)(({ theme, open }) => ({
  '& .MuiPaper-root': {
    width: '35%',
    overflow: 'visible',
    transform: open ? 'translateX(0%)' : 'translateX(35%)',
    transition: 'all 0.3s ease',
    boxShadow: '-3px 3px 4px 0px #0000000A',
  },
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
}));