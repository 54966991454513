import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog, DialogActions, DialogContent, IconButton, List, ListItem, ListItemText, Typography } from '@mui/material';
import axios from 'axios';
import React, { useMemo } from 'react';
import { niceDateTime } from '../helpers/converters';
import { applicationWorkflowSteps } from '../helpers/documentTypeMappings';
import { FileType, RequiredDocument } from '../helpers/types';
import { useRequiredDocuments } from '../contexts/RequiredDocumentsContext';
import { SFLoanApplication } from '@backend/types/Loan';
import { useOutletContext } from 'react-router-dom';

interface MoveFileModalProps {
  open: boolean;
  onClose: () => void;
  file: FileType | null;
  fromStep: string;
  fromDoc: string;
}

const MoveFileModal: React.FC<MoveFileModalProps> = ({ open, onClose, file, fromStep, fromDoc }) => {
  const { state: { requiredDocuments }, fetchRequiredDocuments } = useRequiredDocuments();
  const app = useOutletContext() as SFLoanApplication;
  const appId = app.Id;
  const [selectedDocumentId, setSelectedDocumentId] = React.useState<string | null>(null);

  // Filter out the document type that the file is currently in and sort the remaining documents alphabetically by name
  const filteredDocuments = useMemo(() => {
    return requiredDocuments
      .filter(doc => doc.document_type.name !== fromDoc)
      .sort((a, b) => a.requirement_name.localeCompare(b.requirement_name));
  }, [requiredDocuments, fromDoc]);

  const handleMoveFile = async () => {
    if (!file) return

    try {
      await axios.patch(`/api/v1/loans/file/${file.id}`, { required_doc_id: selectedDocumentId });
      await fetchRequiredDocuments(appId);

    } catch (err) {
      console.error('Failed to move file', err);
    } finally {
      onClose();

      // Temporary fix until state management is improved
      window.location.reload();
    }
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: 4, py: 2 }}>
        <Typography variant="h6">Move To</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', px: 4 }}>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: '14px',
            color: 'text.secondary',
          }}
        >
          You are moving: <span style={{ fontWeight: 'bold' }}>{file?.file_name || ''}</span>
        </Typography>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: '14px',
            color: 'text.secondary',
          }}
        >
          {/* TODO: Remove the reference to Other when we remove the 'Other' category */}
          From: <span style={{ fontWeight: 'bold' }}>{fromDoc} ({fromStep ? applicationWorkflowSteps[fromStep as keyof typeof applicationWorkflowSteps] : 'Other'})</span>
        </Typography>
      </Box>
      <DialogContent>
        <Typography sx={{ fontWeight: 400, fontSize: '16px', mb: 1, ml: 1 }}>To:</Typography>
        <Box sx={{ maxHeight: 300, overflow: 'auto', border: '1px solid #e0e0e0', borderRadius: 2, p: 2, mx: 1 }}>
          <List>
            {filteredDocuments.map((doc) => (
              <ListItem
                key={doc.id}
                onClick={() => setSelectedDocumentId(doc.id)}
                sx={{
                  bgcolor: selectedDocumentId === doc.id ? 'action.selected' : 'inherit',
                  '&:hover': { cursor: 'pointer', backgroundColor: 'action.selected' }
                }}
              >
                <ListItemText primary={
                  <>
                    {/* TODO: Remove the reference to Other when we remove the 'Other' category */}
                    {doc.requirement_name} ({doc.required_for_step ? applicationWorkflowSteps[doc.required_for_step as keyof typeof applicationWorkflowSteps] : 'Other'}) - {' '}
                    <Typography
                      component="span"
                      sx={{
                        fontSize: '12px',
                        color: 'text.secondary',
                      }}
                    >
                      {niceDateTime(new Date(doc.created_at))} by {doc.owner?.name || 'Unassigned'}
                    </Typography>
                  </>
                } />
              </ListItem>
            ))}
          </List>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: 2,
          alignItems: 'center',
          mb: 2
        }}
      >
        <Button onClick={onClose} color="primary" variant="outlined">Cancel</Button>
        <Button onClick={handleMoveFile} color="primary" variant="contained" disabled={!selectedDocumentId}>Move</Button>
      </DialogActions>
    </Dialog>
  );
};

export default MoveFileModal;