import React, { forwardRef } from 'react';
import { SvgIconProps, SvgIcon } from '@mui/material';


export const EditIcon = forwardRef<SVGSVGElement, SvgIconProps>(
    function EditIcon(props, ref) {
      return (
        <SvgIcon ref={ref} {...props}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
          <svg width="15" height="15" viewBox="-2 -2 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.50772 0.640833L0.912841 7.12001C0.85959 7.17073 0.817126 7.23134 0.787914 7.29831C0.758702 7.36529 0.743325 7.43729 0.742676 7.51016L0.756833 9.48875C0.757248 9.63644 0.81715 9.77797 0.92345 9.8824C1.02975 9.98684 1.1738 10.0457 1.32413 10.0461L3.33806 10.06C3.41262 10.0621 3.48675 10.0482 3.55532 10.0193C3.62389 9.99044 3.68525 9.94729 3.73517 9.89282L10.33 3.41364C10.557 3.19068 10.557 2.84234 10.33 2.63338L8.31618 0.640858C8.26354 0.587924 8.20067 0.545862 8.13125 0.517144C8.06183 0.488426 7.98727 0.473633 7.91195 0.473633C7.83663 0.473633 7.76207 0.488426 7.69265 0.517144C7.62323 0.545862 7.56036 0.587924 7.50772 0.640858V0.640833ZM1.89144 8.9314V7.7331L6.16038 3.53905L7.38008 4.73735L3.09695 8.94533L1.89144 8.9314ZM9.12453 3.0235L8.1743 3.95706L6.9546 2.75873L7.90483 1.82517L9.12453 3.0235Z" fill="#2196F3"/>
          </svg>
        </SvgIcon>
      );
    }
  );