import { Box, List, ListItem, Typography } from '@mui/material';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { canAccessToolsAnnouncementsTab } from '../../helpers/featurePermissionRules';

export const ToolsSideNav = () => {
  const path = useLocation().pathname;
  return (
    <Box
      component="nav"
      sx={{
        bgcolor: '#fff',
        display: 'flex',
        borderRight: '1px solid lightgrey',
        flexDirection: 'column',
        gap: '1em',
      }}
    >
      <List
        sx={{ p: '0', display: 'flex', flexDirection: 'column', width: '12em' }}
      >
        <Link
          to={`acr-calculator`}
          style={{ color: 'black', textDecoration: 'none' }}
        >
          <ListItem
            className={`side-nav-link ${
              path.includes('acr-calculator') ? 'side-nav-active' : ''
            }`}
          >
            <Typography variant="caption">ACR Calculator</Typography>
          </ListItem>
        </Link>
        <Link
          to={`consumption-estimator`}
          style={{ color: 'black', textDecoration: 'none' }}
        >
          <ListItem
            className={`side-nav-link ${
              path.includes('consumption-estimator') ? 'side-nav-active' : ''
            }`}
          >
            <Typography variant="caption">Consumption Estimator Calculator</Typography>
          </ListItem>
        </Link>
        <Link
          to={`powerlytics`}
          style={{ color: 'black', textDecoration: 'none' }}
        >
          <ListItem
            className={`side-nav-link ${
              path.includes('powerlytics') ? 'side-nav-active' : ''
            }`}
          >
            <Typography variant="caption">Powerlytics Income Lookup</Typography>
          </ListItem>
        </Link>
        {canAccessToolsAnnouncementsTab() && (
          <Link
            to={`announcements`}
            style={{ color: 'black', textDecoration: 'none' }}
          >
            <ListItem
              className={`side-nav-link ${
                path.includes('announcements') ? 'side-nav-active' : ''
              }`}
            >
              <Typography variant="caption">Announcements</Typography>
            </ListItem>
          </Link>
        )}
        <Link
            to={`sunstone-api-tester`}
            style={{ color: 'black', textDecoration: 'none' }}
          >
            <ListItem
              className={`side-nav-link ${
                path.includes('sunstone-api-tester') ? 'side-nav-active' : ''
              }`}
            >
              <Typography variant="caption">Sunstone API Tester</Typography>
            </ListItem>
          </Link>
      </List>
    </Box>
  );
};
