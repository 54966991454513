import {
  AccountCircle,
  Logout,
  Search,
  SupervisorAccount,
} from '@mui/icons-material';
import { Grid, InputAdornment, Menu, MenuItem, TextField } from '@mui/material';
import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { isAdmin, logout } from '../helpers/auth';
import { canViewHeaderLoansSearchBar } from '../helpers/featurePermissionRules';
import { NavBar } from './navigation/NavBar';

const ssc = new URL('../assets/logo.png', import.meta.url);

export function Header() {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const loansPath = '/loans';
  const currentPath = useLocation().pathname;
  const isOnLoansPage = currentPath.endsWith(loansPath);

  const handleMenuClick = (event) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleSearchEnter = (event) => {
    navigate(`/loans?search=${event.target.value}`);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const navigateToAdmin = () => {
    navigate('/admin');
    setOpen(false);
  };

  return (
    <Grid
      container
      direction={'row'}
      alignItems={'center'}
      sx={{
        backgroundColor: 'white',
        borderBottom: '1px solid lightgrey',
        pl: '1rem',
        pr: '1rem',
        position: 'fixed',
        top: 0,
        zIndex: 1300,
      }}
    >
      <Grid item xs={2} xl={3}>
        <Link to={'/'}>
          <img width={180} src={String(ssc)} alt="SSC" />
        </Link>
      </Grid>
      <Grid item xs={8} xl={5}>
        <NavBar />
      </Grid>

      <Grid
        container
        alignItems={'right'}
        direction={'row'}
        xs={2}
        xl={4}
        sx={{
          backgroundColor: 'white',
          pl: '1rem',
          pr: '1rem',
          justifyContent: 'flex-end',
        }}
      >
        {canViewHeaderLoansSearchBar() &&
          !isOnLoansPage && (
            <Grid xs={11} xl={11} textAlign={'right'}>
              <TextField
                label="Search Loan Apps"
                variant="outlined"
                sx={{
                  bgcolor: 'white',
                  minWidth: '40%',
                  borderRadius: '5px',
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                size="small"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSearchEnter(e);
                  }
                }}
              ></TextField>
            </Grid>
          )}
        <Grid
          item
          xs={isOnLoansPage ? 12 : 1}
          xl={isOnLoansPage ? 12 : 1}
          textAlign={'right'}
        >
          <AccountCircle
            sx={{ cursor: 'pointer' }}
            onClick={handleMenuClick}
            fontSize="large"
          />
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={handleLogout}>
              <Logout /> Log out
            </MenuItem>
            {isAdmin() && (
              <MenuItem onClick={navigateToAdmin}>
                <SupervisorAccount /> Admin Page
              </MenuItem>
            )}
          </Menu>
        </Grid>
      </Grid>
    </Grid>
  );
}
