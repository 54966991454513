import React from 'react';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

export const CustomArrowDown = (props) => {
  return (

      <KeyboardArrowDownRoundedIcon 
        {...props}
          sx={{ 
            fontSize: '16px',
            color: '#868795',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#FFFFFF',
            borderRadius: '50%',
            boxShadow: '-1px 3px 7px -1px #0000001A',
          }}
      />
  );
}
